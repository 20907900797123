import React from 'react'
import PropTypes from 'prop-types'
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    if (isFirstItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ChevronLeftIcon className='text-white'/>
        </div>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

    if (isLastItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ChevronRightIcon className='text-white'/>
        </div>
    );
}

function ChannelList(props) {
    const {channelList, channelChange} = props

    return (
        <ScrollMenu
            wrapperClassName='mt-2 pt-0.5 pb-3 h-auto relative border-b-1'
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
            {channelList.map((channel, index) => {
                const {channel_id, thumbnail, name} = channel.attributes
                return (<div className='w-30 h-36 mx-2' key={channel_id} onClick={() => channelChange(channel.attributes)}>
                        <img
                            className='rounded-full w-30 h-30 bg-slate-100 shadow'
                            src={thumbnail.toString()}
                            alt={''}
                            referrerPolicy="no-referrer"
                            onError={(e) => (e.currentTarget.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')}
                        />
                    <h5 className='text-xs truncate text-center overflow-hidden mt-1 h-4'>{name}</h5>
                </div>)
            })
            }
        </ScrollMenu>
    )
}

ChannelList.propTypes = {
    gameList: PropTypes.array.isRequired,
    gameChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}

export default ChannelList

