import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import StrapiAPI from "../../data/strapi";
import VideoThumb from '../videoThumb/videoThumb'
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";


function LeftArrow() {
    const {isFirstItemVisible, scrollPrev} =
        React.useContext(VisibilityContext);

    if (isFirstItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ChevronLeftIcon className='text-white'/>
        </div>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext} = React.useContext(VisibilityContext);

    if (isLastItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ChevronRightIcon className='text-white'/>
        </div>
    );
}

function ChannelVideoList(props) {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const {channel_id, filter = 'new'} = props;

    useEffect(() => {
        setLoading(true)
        if (filter === 'top') {
            StrapiAPI.getTopChannelVideos(channel_id)
                .then((response) => {
                    setVideos(response.data);
                    setLoading(false)
                })
        } else {
            StrapiAPI.getRecentChannelVideos(channel_id)
                .then((response) => {
                    setVideos(response.data);
                    setLoading(false)
                })
        }
    }, [filter, channel_id])

    if (loading) {
        return (
            <div className='flex flex-row py-2 overflow-hidden h-58 items-center '>
                <div className='flex flex-row space-x-1.5 mt-1 flex-nowrap overflow-x-scroll'>
                    <div className='text-center w-screen'>loading...</div>
                </div>
            </div>
        );
    }

    if (videos.length === 0) {
        return (
            <div className='flex flex-row py-2 overflow-hidden h-58 items-center '>
                <div className='flex flex-col space-x-1.5 mt-1 flex-nowrap overflow-x-scroll'>
                    <div className='text-center w-screen align-middle'>No videos found</div>
                </div>
            </div>
        );
    }

    return (
        <ScrollMenu
            wrapperClassName='mt-1 py-2 h-58 relative'
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
                {videos.map((video, index) => {
                    const {attributes, attributes: {video_id}} = video
                    return (
                        <VideoThumb key={`${video_id}${index}`} {...attributes} index={index}/>
                    )
                })
                }
        </ScrollMenu>
    );
}

ChannelVideoList.propTypes = {
    channel_id: PropTypes.string.isRequired,
};

export default ChannelVideoList;

