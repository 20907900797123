import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import CategoryGames from '../categoryGames/categoryGames'

import STRAPI from '../../data/strapi'

const MESSAGES = [
    'on it...',
    'loading ...',
    'got your list ...',
    'searching for videos about your games ...',
    'looks like you have a lot of games ...',
    'this is taking longer than expected ...',
    'almost there ...'
]

function CategoryCollection() {
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState(MESSAGES[0])
    const [error, setError] = useState(false)
    const [collection, setCollection] = useState(false)

    useEffect(() => {
        const collection = localStorage.getItem('bgg_collection')

        if (collection) {
            const parsedCollection = JSON.parse(collection)
            setCollection(parsedCollection)
        }
        setLoading(false)
    }, [])

    const setLoadingMessagesDelay = () => {
        for(let i = 0; i < MESSAGES.length; i++) {
            setTimeout(() => {setLoadingMessage(MESSAGES[i])}, 6000 * i)
        }
    }

    const _loadCollection = async () => {
        if (username.length < 1) {
            return
        }

        setLoading(true)
        setLoadingMessagesDelay()

        const collection = await STRAPI.getUserCollection(username)

        if (collection.errors) {
            setError(collection.error._text)
        } else if (collection.length < 1) {
            setError('Username not found, collection empty or to big')

        } else {
            setCollection(collection)
            localStorage.setItem('bgg_collection', JSON.stringify(collection))
        }

        setLoading(false)
        setLoadingMessage(MESSAGES[0])
    }

    const clearCollection = () => {
        localStorage.removeItem('bgg_collection')
        setCollection(false)
    }

    return (
        <React.Fragment>
            {collection && <span onClick={clearCollection} className='absolute top-2 right-3 text-md font-bold text-gray-500'>✖️</span>}
            {(!collection && !loading && !error) &&
            <div className='flex flex-col pb-2 overflow-hidden h-28'>
                <div className='flex flex-col space-x-2.5 px-2 mt-2 flex-nowrap overflow-x-scroll'>
                    <p className='ml-2 mb-1 mt-2.5 text-sm'>Load your own game collection from BGG</p>
                    <div className='w-full flex flex-row m-0'>
                        <input
                            type='text'
                            autoComplete='on'
                            className='h-9 px-2 py-1 placeholder-gray-400 text-gray-600 bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none w-1/2 max-w-xs'
                            placeholder='BGG username'
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <button
                            className='h-9 bg-transparent border border-solid border-gray-500 bg-gray-100 text-sm px-5 py-2 rounded outline-none focus:outline-none ml-2 mb-1'
                            type='button'
                            onClick={_loadCollection}
                        >Search  🔍
                        </button>
                    </div>
                </div>
            </div>
            }
            {loading && <div className='flex flex-col w-full h-28 p-4 justify-center items-center text-sm'>
                <div>{loadingMessage}</div>
            </div>}
            {error && <div onClick={() => (setError(false))} className='flex flex-col w-full h-28 p-4 justify-center items-center text-sm'>
                <div className='cursor-pointer'>{error} - click here to retry</div>
            </div>}
            {collection && <CategoryGames type={'collection'} items={collection}/>}
        </React.Fragment>

    )
}

CategoryCollection.propTypes = {
    items: PropTypes.array.isRequired
}


export default CategoryCollection

