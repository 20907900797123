import React from 'react'

import CategoryGames from '../../components/categoryGames/categoryGames'
import CategoryVideos from '../../components/categoryVideos/categoryVideos'
import CategoryCollection from '../../components/categoryCollection/categoryCollection'
import CategoryChannel from '../../components/categoryChannels/categoryChannels'

import Module from '../../components/module/module'


const Home = ({categories}) => {
    const _renderCategories = () => {
        return categories.map((category) => {
                const {attributes: {type, title, items = false, slug}} = category

                switch (type) {
                    case 'games':
                    case 'list':
                        return items ? (
                            <Module title={title} key={slug}>
                                <CategoryGames items={items} type={slug}/>
                            </Module>
                        ) : null
                    case 'videos':
                        return items ? (
                            <Module title={title} key={slug}>
                                <CategoryVideos items={items}/>
                            </Module>
                        ) : null
                    case 'collection':
                        return items ? (
                            <Module title={title} key={slug}>
                                <CategoryCollection/>
                            </Module>
                        ) : null
                    case 'channel':
                        return items ? (
                            <Module title={title} key={slug}>
                                <CategoryChannel/>
                            </Module>
                        ) : null
                }
            }
        )
    }

    return (
        <React.Fragment>
            {_renderCategories()}
        </React.Fragment>
    )
}

export default Home
