import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Link from "next/link";

import ChannelVideoList from '../videoList/channelVideoList'
import ChannelList from '../channelList/channelList'

import StrapiAPI from "../../data/strapi";

const CategoryChannels = () => {
    const [selectedChannel, setSelectedChannel] = useState(false)
    const [channelList, setChannelList] = useState([])
    const [selectedFilter, setSelectedFilter] = useState('new')

    useEffect(() => {
        _load().then()
    }, [])

    const _load = async () => {
        const channels = await StrapiAPI.getChannelsBySubscribers()
        const cleanedList = channels.data.filter(c => (c.attributes.video_count === null || c.attributes.video_count === 0) || c.attributes.video_count >= 8)
        setChannelList(cleanedList)
        setSelectedChannel(cleanedList[0].attributes)
    }

    return (
        <React.Fragment>
            {channelList && <ChannelList channelList={channelList} channelChange={(channel) => {
                setSelectedChannel(channel)
                setSelectedFilter('new')
            }}/>}
            <div className='flex flex-row justify-between sm:flex-row-reverse text-right -mb-1 mt-2 ml-2'>
                <div className='btn-group'>
                    <button className={`btn btn-xs text-s ${selectedFilter === 'new' ? 'btn-active' : ''}`} onClick={() => setSelectedFilter('new')}>Recent</button>
                    <button className={`btn btn-xs text-s ${selectedFilter === 'top' ? 'btn-active' : ''}`} onClick={() => setSelectedFilter('top')}>Top</button>
                </div>
                <div className='hidden btn btn-xs'><Link href={`/channels/`} >Channel Page</Link></div>
            </div>
            {selectedChannel && <ChannelVideoList channel_id={selectedChannel.channel_id} filter={selectedFilter} />}
        </React.Fragment>
    )
}

CategoryChannels.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};

export default CategoryChannels

