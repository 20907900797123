import React, {} from 'react'
import PropTypes from 'prop-types'
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

import VideoThumb from '../videoThumb/videoThumb'

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    if (isFirstItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            <ChevronLeftIcon className='text-white' />
        </div>
    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    if (isLastItemVisible) return null

    return (
        <div className='btn btn-circle glass' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            <ChevronRightIcon className='text-white' />
        </div>
    );
}

function CategoryVideos(props) {
    const [items] = React.useState(props.items || []);

    if (items.length === 0) {
        return null
    }

    return (
        <ScrollMenu
            wrapperClassName='mt-2 pb-2 h-auto relative'
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
        >
            {items.map((video, index) => {
                const {video_id} = video
                return (
                    <VideoThumb key={`${video_id}${index}`} {...video} index={index}/>
                );
            })
            }
        </ScrollMenu>


    )
}

CategoryVideos.propTypes = {
    items: PropTypes.array.isRequired
};


export default CategoryVideos

